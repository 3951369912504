module.exports = {
    defaultLanguage: 'es',
    scssVariables: './src/styles/bago2023',
    randomNickname:  () => { return RandomNickname() },
    profileColor: {saturation: 80, brighness: 50},
    favicon: 'bagofavicon.ico',
    defaultPassword: 'default'
}

function RandomNickname() {
    const animales = ["Gonzalo","Paula","Manuel","Matías","Guadalupe","Soledad","Diego","Lucía","Maru","Bernardo","Valeria","Melina","Diego","Delfina"];
    const adjetivos = ["Jimenez", "Bernárdez","Moiguer","Trejo","Taglieri","Francassi","Fuxman Bass","Sierra","Mena","Jauregi Lorda","Anschutz"];
    const rand1 = Math.round(Math.random() * 99999);
    const animal = animales[rand1 % animales.length];
    const rand2 = Math.round(Math.random() * 99999);
    const adjetivo = adjetivos[rand2 % adjetivos.length];
    return animal + " " + adjetivo;
}