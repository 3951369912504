module.exports = {
    defaultLanguage: 'es',
    scssVariables: './src/styles/adama2023',
    randomNickname:  () => { return RandomNickname() },
    profileColor: {saturation: 80, brighness: 90},
    favicon: 'adamafavicon.ico',
    defaultPassword: 'default'
}

function RandomNickname() {
    //const animales = ["Sirio","Canopus","Rigel","Arturo","Vega","Capella","Rigel","Procyon","Achernar","Betelgeuse","Hadar","Aldebarán","Antares","Spica","Pollux","Fomalhaut","Deneb","Mimosa"];
    //const adjetivos = ["Rojo", "Naranja","Azul","Celeste","Blanca","Amarilla","Violeta","Negro","Lila","Rosa","Blanco","Negra","Verde","Rosado","Azulado"];
    const animales = ["GORRION", "LEON", "PAJARO", "LORO", "CABALLO", "AGUILA", "HUEMUL", "LLAMA", "PERRO", "GATO", "CAMELLO", "FOCA", "JABALI", "RANA", "DELFIN", "GIRAFA", "CASTOR", "ALCON", "ZORRO", "LORO", "SURICATA"];
    const adjetivos = ["RECICLAD", "ECO", "ELEGANTE", "FIEL", "JUVENIL", "AMENO", "FLEXIBLE", "SERVIL", "ALEGRE", "DULCE", "AMABLE", "NOTABLE", "SUAVE", "INQUIETO", "MODERNO", "ENORME", "NOBLE", "FEROZ", "MUSICAL", "CULTURAL", "GENIAL"];
    const rand1 = Math.round(Math.random() * 99999);
    const animal = animales[rand1 % animales.length];
    const rand2 = Math.round(Math.random() * 99999);
    const adjetivo = adjetivos[rand2 % adjetivos.length];
    return animal + " " + adjetivo;
}